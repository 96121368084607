import type { ReactNode } from "react";
import React from "react";
import { Badge, Card, Heading, IconArrowRight, Paragraph, VisuallyHidden } from "@purpurds/purpur";
import { VectorSearchDocument } from "@telia/b2x-elastic-search-client";
import classnames from "classnames/bind";

import styles from "./b2x-result-card.module.scss";
import { CATEGORIES } from "./constants";

const cx = classnames.bind(styles);

type ResultCardProps = {
  ["data-testid"]?: string;
  result: VectorSearchDocument;
  onClick: () => void;
  image: ReactNode;
  showAll?: boolean;
};

export const ResultCard = ({
  ["data-testid"]: dataTestId,
  result,
  onClick,
  image,
  showAll,
}: ResultCardProps) => {
  const { title, description, segment, url, category } = result;
  const currentCategory = CATEGORIES[category as keyof typeof CATEGORIES];

  if (!url || !(title || description)) {
    return null;
  }

  return (
    <Card className={cx("result-card")} data-testid={dataTestId}>
      <div className={cx("badges")}>
        {currentCategory && segment && (
          <Badge variant="information" showIcon={false}>
            {currentCategory} &bull; {segment === "privat" ? "Privat" : "Företag"}
          </Badge>
        )}
      </div>
      {image && image}
      <a data-search-result className={cx("link")} href={url} onClick={onClick}>
        <VisuallyHidden>{title || description}</VisuallyHidden>
      </a>
      <div className={cx("text")}>
        {title && (
          <Heading className={cx("title")} variant="title-100" tag="h3" aria-hidden>
            {title}
          </Heading>
        )}
        {description && (
          <Paragraph className={showAll ? cx("page-description") : cx("drawer-description")}>
            {description}
          </Paragraph>
        )}
      </div>

      <div className={cx("arrow-icon")}>
        <IconArrowRight size="xs" />
      </div>
    </Card>
  );
};
